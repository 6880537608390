@import '../../../styles/basics';

.featured-story {
  :global {
    .wrapper {
      display: block;
      position: relative;
      height: 300px;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        height: 100%;

        &:hover img {
          transform: scale(1.025);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-transition: transform 0.2s;
        -moz-transition: transform 0.2s;
        -ms-transition: transform 0.2s;
        -o-transition: transform 0.2s;
        transition: transform 0.2s;
      }

      .date {
        margin-left: 8px;
        color: $white;
        @include media-breakpoint-up(md) {
          margin-left: 24px;
        }
      }

      .gradient {
        background-image: linear-gradient(to bottom, rgba(33, 36, 49, 0), $slate-1200);
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 173px;
        z-index: 1;
        @include media-breakpoint-up(md) {
          background-image: linear-gradient(to bottom, rgba(33, 36, 49, 0), rgba(33, 36, 49, 0.9));
        }
      }

      .bottom {
        padding: 0 10px 12px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        @include media-breakpoint-up(md) {
          padding: 0 20px 24px;
        }

        h3 {
          margin-top: 12px;
          color: $white;
        }
      }

      &:hover,
      &:focus-visible {
        h3 {
          text-decoration: underline;
        }
      }
    }
  }
}
