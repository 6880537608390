@import '../../../../styles/basics';

.bb-featured-stories {
  &:global(.bb) {
    @include bb-standalone-spacing();
  }

  :global {
    .item-with-image {
      padding-bottom: 24px;
      border-bottom: 1px solid $slate-200;
      @include media-breakpoint-up(md) {
        padding-bottom: 0;
        border: none;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .main-link {
        display: flex;
        @include media-breakpoint-up(md) {
          display: block;
        }

        &:hover,
        &:focus-visible {
          h3.title {
            text-decoration: underline;
          }
        }

        .top {
          margin-bottom: 0;
          margin-right: 12px;
          min-width: 100px;
          height: 100px;
          width: 100px;
          @include media-breakpoint-up(md) {
            margin-bottom: 12px;
            margin-right: 0;
            width: auto;
            height: auto;
            min-width: 0;
            min-height: 0;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content {
          flex-grow: 1;
          margin-top: -6px;
          @include media-breakpoint-up(lg) {
            margin-top: 0;
          }

          h3 {
            font-size: 14px;
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
