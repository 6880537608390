@import '../../../../styles/basics';

.bb-featured-country-news {
  &:global(.bb) {
    @include bb-standalone-spacing();
  }

  // on mobile ...
  :global {
    .bb-featured-stories {
      // ... avoid double-padding with nested BB containers
      > .container {
        padding-left: 0;
        padding-right: 0;

        // ... hide news descriptions on mobile
        .item-with-image .description {
          display: none;
        }
      }
    }
    .main-link {
      &:hover,
      &:focus-visible {
        h3 {
          text-decoration: underline;
        }
      }
    }
  }
}
